import React from 'react'
import cx from 'classnames'
import styles from './input.module.css'
import { useTranslation } from 'react-i18next'

export const Input = props => {
  const { error, onChange, name } = props

  return (
    <div className={styles.block}>
      <input
        {...props}
        onChange={onChange}
        className={cx(styles.input, {
          [styles.error]: !!error,
        })}
      />
      {error && <ErrorText error={error} name={name} />}
    </div>
  )
}

const ErrorText = props => {
  const { error, name } = props
  const { t } = useTranslation()
  const isNameInput = name === 'name'
  return (
    <span className={styles.errorMessage}>
      {isNameInput ? t('forms.formValidationNameRequiredMessage') : error}
    </span>
  )
}
