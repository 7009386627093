import React from 'react'
import styles from './checkbox.module.css'

export const Checkbox = ({ children, checked, ...props }) => {
  return (
    <div className={styles.checkbox}>
      <div className={styles.input}>
        <label className={styles.label}>
          <input {...props} checked={checked} type="checkbox" />
          <div className={styles.text}>{children}</div>
        </label>
      </div>
    </div>
  )
}
