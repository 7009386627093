import React from 'react'
import cx from 'classnames'

import { CheckRadioGreenOnIcon, LogoIcon } from './icons'
import styles from './steps.module.css'
import { useTranslation } from 'react-i18next'

export const Steps = props => {
  const { step } = props

  return (
    <>
      <div className={styles.steps}>
        <div className={styles.container}>
          <StepOneBlock step={step}>
            <StepOne step={step} />
          </StepOneBlock>
          <StepTwoBlock step={step}>
            <StepTwo step={step} />
          </StepTwoBlock>
          <StepThreeBlock step={step}>
            <StepThree step={step} />
          </StepThreeBlock>
        </div>
      </div>
    </>
  )
}

const StepOneBlock = props => {
  const { step, children } = props

  return (
    <span
      className={cx(styles.stepBlock, {
        [styles.active]: isStepOne(step),
        [styles.success]: isStepOneOrMore(step),
      })}
    >
      {children}
    </span>
  )
}

const StepTwoBlock = props => {
  const { step, children } = props

  return (
    <span
      className={cx(styles.stepBlock, {
        [styles.active]: isStepTwo(step),
        [styles.success]: isStepTwoOrMore(step),
      })}
    >
      {children}
    </span>
  )
}

const StepThreeBlock = props => {
  const { step, children } = props

  return (
    <span
      className={cx(styles.stepBlock, {
        [styles.active]: isStepThree(step),
        [styles.success]: isStepThreeOrMore(step),
      })}
    >
      {children}
    </span>
  )
}

const StepOne = props => {
  const { step } = props
  const { t } = useTranslation()

  if (isStepOneOrMore(step)) {
    return (
      <span className={cx(styles.step)}>
        <span className={styles.number}>
          <CheckRadioGreenOnIcon />
        </span>
      </span>
    )
  }

  return (
    <span
      className={cx(styles.step, {
        [styles.current]: isStepOne(step),
      })}
    >
      <span className={styles.number}>1</span>
      <p>{t('home.orderFoodOnline.steps.step1')}</p>
    </span>
  )
}

const StepTwo = props => {
  const { step } = props
  const { t } = useTranslation()

  if (isStepTwoOrMore(step)) {
    return (
      <span className={cx(styles.step)}>
        <span className={styles.number}>
          <CheckRadioGreenOnIcon />
        </span>
      </span>
    )
  }

  return (
    <span
      className={cx(styles.step, {
        [styles.current]: isStepTwo(step),
      })}
    >
      <span className={styles.number}>2</span>
      <p>{t('home.orderFoodOnline.steps.step2')}</p>
    </span>
  )
}

const StepThree = props => {
  const { step } = props
  const { t } = useTranslation()

  if (isStepThreeOrMore(step)) {
    return (
      <span className={cx(styles.step)}>
        <span className={styles.number}>
          <CheckRadioGreenOnIcon />
        </span>
      </span>
    )
  }

  return (
    <span
      className={cx(styles.step, {
        [styles.current]: isStepThree(step),
      })}
    >
      <span className={styles.number}>3</span>
      <p>{t('home.orderFoodOnline.steps.step3')}</p>
    </span>
  )
}

const isStepOne = step => {
  return step === 1
}

const isStepOneOrMore = step => {
  return step >= 2
}

const isStepTwo = step => {
  return step === 2
}

const isStepTwoOrMore = step => {
  return step >= 3
}

const isStepThree = step => {
  return step === 3
}

const isStepThreeOrMore = step => {
  return step >= 4
}
