import React from 'react'
import cx from 'classnames'
import { Input } from './input'
import {
  PromoIcon,
  PromoFailIcon,
  PromoSuccessIcon,
  CheckRadioGreenOnIcon,
} from '../icons'
import styles from './promo-code-input.module.css'

export const PromoCodeStatus = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  FAIL: 'fail',
}

export const PromoCodeInput = props => {
  const {
    placeholder,
    status = 'default',
    value,
    error,
    fullWidth,
    onClick,
    onChange,
    onClear,
    disabled,
    children,
  } = props

  const handleClick = () => {
    if (onClick) {
      onClick(value)
    }
  }

  const handleChange = event => {
    if (onChange) {
      onChange(event.target.value)
    }
  }

  const handleClear = () => {
    if (onClear) {
      onClear()
    }
  }

  return (
    <div
      className={cx(styles.promo, {
        [styles.success]: isSuccessStatus(status),
        [styles.fail]: isFailStatus(status),
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
      })}
    >
      <PromoIconFactory status={status} />
      <span className={styles.input}>
        <Input
          placeholder={placeholder}
          value={value}
          error={isFailStatus(status) ? error : undefined}
          onChange={handleChange}
          disabled={disabled}
          name="promo"
        />
      </span>
      {isDefaultStatus(status) && (
        <Button onClick={handleClick} disabled={disabled}>
          {children}
        </Button>
      )}
      {isFailStatus(status) && value.length > 0 && (
        <ButtonTransparent onClick={handleClear}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
          >
            <path
              id="icons8-macos-close_1_"
              data-name="icons8-macos-close (1)"
              d="M8,3a5,5,0,1,0,5,5A5,5,0,0,0,8,3ZM9.961,9.372a.417.417,0,1,1-.589.589L8,8.589,6.628,9.961a.417.417,0,0,1-.589-.589L7.411,8,6.039,6.628a.417.417,0,0,1,.589-.589L8,7.411,9.372,6.039a.417.417,0,0,1,.589.589L8.589,8Z"
              transform="translate(-3 -3)"
              fill="#87a5c0"
            />
          </svg>
        </ButtonTransparent>
      )}
      {isSuccessStatus(status) && <SuccessIcon />}
      {isFailStatus(status) && <ErrorText error={error} />}
    </div>
  )
}

const Button = props => {
  const { onClick, disabled, children } = props
  return (
    <span className={styles.buttonBlock}>
      <button
        type="button"
        onClick={onClick}
        className={styles.button}
        disabled={disabled}
      >
        {children}
      </button>
    </span>
  )
}

const ButtonTransparent = props => {
  const { onClick, disabled, children } = props
  return (
    <span className={cx(styles.buttonBlock, styles.buttonBlockTransparent)}>
      <button
        type="button"
        onClick={onClick}
        className={cx(styles.button, styles.buttonTransparent)}
        disabled={disabled}
      >
        {children}
      </button>
    </span>
  )
}

const SuccessIcon = () => {
  return (
    <span className={styles.successIconBlock}>
      <CheckRadioGreenOnIcon />
    </span>
  )
}

const ErrorText = props => {
  const { error } = props
  return <span className={styles.error}>{error}</span>
}

const PromoIconFactory = ({ status }) => {
  if (isSuccessStatus(status)) {
    return (
      <span className={styles.icon}>
        <PromoSuccessIcon />
      </span>
    )
  }

  if (isFailStatus(status)) {
    return (
      <span className={styles.icon}>
        <PromoFailIcon />
      </span>
    )
  }

  return (
    <span className={styles.icon}>
      <PromoIcon />
    </span>
  )
}

const isDefaultStatus = status => {
  return PromoCodeStatus.DEFAULT === status
}

const isSuccessStatus = status => {
  return PromoCodeStatus.SUCCESS === status
}

const isFailStatus = status => {
  return PromoCodeStatus.FAIL === status
}
