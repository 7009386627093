import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { useGetters } from '../../model'
import { Button2 } from '../../../../../components/button2'
import styles from './order-food-online-sidebar.module.css'
export const returunDays = (value) => {
  if(value === '2 týdny'){
    return 10
  }else if(value === 'Měsíc'){
    return 20
  }else if(value === 'Dva měsíce'){
    return 40
  }else{
    return 0
  }
}
export const OrderFoodOnlineSidebarCalculationPriceItem = ({ size = 'lg' }) => {
  const { t } = useTranslation()
  const isGoodbyeUnhealthyForm =
    localStorage.getItem('formName') === 'say-goobay-unhealthy-Eating'

    let dataFromLocalStorage = JSON.parse(
      localStorage.getItem('stopEatHealthyFormData')
    )
  const getters = useGetters()

  return (
    <p className={styles.price}>
      {t('home.orderFoodOnline.pricePerDay')}:{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {isGoodbyeUnhealthyForm ? dataFromLocalStorage.totalPrice :  numberFormat(getters.getOrderFoodPrice)} {t('czhKrona')}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarCalculationKcalItem = ({ size = 'lg' }) => {
  const { t } = useTranslation()
 
  const getters = useGetters()

  return (
    <p className={styles.kcal}>
      {t('home.orderFoodOnline.totalPrice')}: {/* Cena za celé období:{' '} */}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {numberFormat(getters.getOrderFoodPriceByDays)} {t('czhKrona')}
      </span>
    </p>
  )
}

export const OrderFoodContactSidebarCalculationPriceItem = ({
  size = 'lg',
  warning = false,
}) => {
  const { t } = useTranslation()

  const getters = useGetters()

  return (
    <div>
      <p className={styles.price}>
        {t('home.orderFoodOnline.pricePerDay')}:{' '}
        <span
          className={cx({
            [styles.itemSizeSmall]: size === 'sm',
            [styles.itemSizeLarge]: size === 'lg',
          })}
        >
          <div>
            {numberFormat(getters.getOrderFoodPrice)} {t('czhKrona')}
          </div>
        </span>
      </p>
      {warning && (
        <p>
          <div className={styles.additionalPrice}>
            Doprava <span>+50Kč</span> za den
          </div>
        </p>
      )}
    </div>
  )
}



export const OrderFoodContactSidebarCalculationKcalItem = ({
  size = 'lg',
  warning = false,
}) => {
  const { t } = useTranslation()

  const getters = useGetters()

  return (
    <div>
      <p className={styles.kcal}>
        {t('home.orderFoodOnline.totalPrice')}:{' '}
        {/* Cena za celé období:{' '} */}
        <span
          className={cx({
            [styles.itemSizeSmall]: size === 'sm',
            [styles.itemSizeLarge]: size === 'lg',
          })}
        >
          <div>
            {numberFormat(getters.getOrderFoodPriceByDays)} {t('czhKrona')}
          </div>
        </span>
      </p>
      {warning && (
        <p>
          <div className={styles.additionalPrice}>
            Doprava <span>+50Kč</span> za den
          </div>
        </p>
      )}
    </div>
  )
}

export const OrderFoodOnlineSidebarDPHPriceItem = ({ size = 'lg' }) => {
  const { t } = useTranslation()

  const getters = useGetters()

  return (
    <p className={styles.price}>
      {t('home.orderFoodOnline.priceDPH')}{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {numberFormat(getters.getOrderFoodDPH)} {t('czhKrona')}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarPriceItemWithDPH = ({ size = 'lg' }) => {
  const { t } = useTranslation()

  const getters = useGetters()

  return (
    <p className={styles.price}>
      {t('home.orderFoodOnline.priceBezDPH')}{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {numberFormat(getters.getOrderFoodPriceByDaysWithoutDPH)}{' '}
        {t('czhKrona')}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarCalculationTransportPerDayItem = ({
  size = 'lg',
}) => {
  const getters = useGetters()
  const { t } = useTranslation()

  const isDeliveryFree = getters.isOrderFoodDeliveryFree

  return (
    <p className={styles.item}>
      {t('home.orderFoodOnline.transportPerDay')}{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {isDeliveryFree
          ? 'Zdarma'
          : `${getters.getOrderFoodDeliveryPrice} ${t('czhKrona')}`}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarCalculationTransportTotalItem = ({
  size = 'lg',
}) => {
  const getters = useGetters()
  const { t } = useTranslation()

  const isDeliveryFree = getters.isOrderFoodDeliveryFree

  return (
    <p className={styles.item}>
      {t('home.orderFoodOnline.transportTotal')}{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {isDeliveryFree
          ? 'Zdarma'
          : `${getters.getOrderFoodDeliveryPriceByDays} ${t('czhKrona')}`}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarCalculationPromoCodeItem = ({
  size = 'lg',
  children,
}) => {
  const { t } = useTranslation()
  return (
    <p className={styles.promo}>
      {t('home.orderFoodOnline.promoDiscount')}{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {children} {t('czhKrona')}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarCalculationTotalBlockItem = ({
  size = 'lg',
  children,
}) => {
  return (
    <div className={styles.blockItem}>
      <OrderFoodOnlineSidebarCalculationTotalItem size={size}>
        {children}
      </OrderFoodOnlineSidebarCalculationTotalItem>
    </div>
  )
}

export const OrderFoodOnlineSidebarCalculationTotalItem = ({
  size = 'lg',
  children,
}) => {
  const { t } = useTranslation()

  return (
    <p className={styles.total}>
      {t('home.orderFoodOnline.totalPrice2')}{' '}
      <span
        className={cx({
          [styles.itemSizeSmall]: size === 'sm',
          [styles.itemSizeLarge]: size === 'lg',
        })}
      >
        {children} {t('czhKrona')}
      </span>
    </p>
  )
}

export const OrderFoodOnlineSidebarOrderContactInformationButtonItem = props => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.resultBlock, styles.orderBlock)}>
      <div className={styles.buttonBlock}>
        <Button2
          {...props}
          size="sm"
          color="secondary"
          colorDisabled="grey"
          buttonType="submit"
          fullWidth
        >
          {t('home.orderFoodOnline.step2.btnText')}
        </Button2>
      </div>
    </div>
  )
}

export const OrderFoodOnlineSidebarPayOrderButtonItem = props => {
  const { t } = useTranslation()

  return (
    <div className={cx(styles.resultBlock, styles.orderBlock)}>
      <div className={styles.buttonBlock}>
        <Button2 {...props} color="secondary" buttonType="submit" fullWidth>
          {t('home.orderFoodOnline.step3.btnText')}
        </Button2>
      </div>
    </div>
  )
}

export const Divider = () => <div className={styles.divider} />

const numberFormat = value => {
  if (!value) return value
  return (value || '')?.toLocaleString().replace(',', ' ')
}
