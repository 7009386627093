import { api } from './request'
import { GATSBY_BRANCH_ID } from '../lib/config'
import {
  formatPhoneNumber,
  getCookie,
  getReferrer,
  getUTM,
} from '../../helpers'
import {
  isOrderFoodNumberOfPeopleCompany,
  isOrderFoodNumberOfPeopleOne,
  isOrderFoodNumberOfPeopleThreeOrMore,
  isOrderFoodNumberOfPeopleTwo,
  isOrderFoodProgramLunchAndDinner,
  isOrderFoodProgramMaintenance,
  isOrderFoodProgramWeightGain,
  isOrderFoodProgramWeightLoss,
  isOrderProgramDuration1Month,
  isOrderProgramDuration2Month,
  isOrderProgramDuration2Weeks,
  isOrderProgramDuration3Month,
} from '../../features/pages/order-food-online/model/getters'

const checkPromoCode = async data => {
  const testovani = localStorage.getItem('website-version')
  return await api.post('/check-promo', {
    branchId: GATSBY_BRANCH_ID,
    promocode: data.promoCode,
    price: data.price,
    testovani,
  })
}

export const checkDeliveryAddress = async address => {
  return await api.post('/delivery/check-address', {
    branchId: GATSBY_BRANCH_ID,
    q: address,
  })
}

export const order = async data => {
  const UTMS = getUTM()
  const isHomepage = window.location.pathname === '/'
  const referrer = isHomepage ? getReferrer() : window.location.href
  const isEnglish = document.location.pathname.includes('/en')
  const testovani = localStorage.getItem('website-version')
  const language = isEnglish ? 'en' : 'cs'

  const isAddDeliveryFee = data?.deliveryAddress?.isPointInPolygon
  const address = data?.deliveryAddress?.label
  const city = data?.city

  const getFormName = (isCompanyOrder, isEnglish) => {
    switch (true) {
      case isCompanyOrder && isEnglish:
        return 'online-objednavka-company-new_en'
      case isCompanyOrder && !isEnglish:
        return 'online-objednavka-company-new'
      case !isCompanyOrder && isEnglish:
        return 'online-objednavka-new_en'
      case !isCompanyOrder && !isEnglish:
        return 'online-objednavka-new'
      default:
        return ''
    }
  }

  const form_name = getFormName(data.isCompanyOrder, isEnglish)

  return await api.post('/app/order', {
    branchId: GATSBY_BRANCH_ID,
    form_name,
    language,
    region: 'CZ',

    name: data.name,
    email: data.email,
    phone: formatPhoneNumber(data.phone),
    checkTerms: data.terms1,
    checkTerms2: data.terms2,
    price: data.price,
    promocode: data.promo,

    diet: getDiet(data),
    length: data.length,
    mealsPerDay: data.numberOfMeals,
    size: getSize(data),
    kcal: data.kcal,

    address,
    date: data.date,
    deliveryTime: getDeliveryTime(data),
    onlinePayment: data.onlinePayment,

    classMenu: data.classMenu,

    cjevent: data.affiliateId,

    city,
    utm_source: UTMS.UTM_SOURCE,
    utm_medium: UTMS.UTM_MEDIUM,
    utm_campaign: UTMS.UTM_CAMPAIGN,
    utm_term: UTMS.UTM_TERM,
    utm_content: UTMS.UTM_CONTENT,

    isAddDeliveryFee: !isAddDeliveryFee,

    paymentData: {
      isCompany: data.isCompanyOrder,
      address: data.companyAddress,
      companyName: data.companyName,
      zip: data.zip,
      regNumber: data.regNumber,
      vatNumber: data.vatNumber,
    },
    exceptions:data.exeptions,

    referrer,
    testovani,
    roistat: getCookie('roistat_visit'),
    ga: getCookie('_ga'),
  })
}

export const application = async data => {
  const UTMS = getUTM()
  const referrer = getReferrer()
  const isEnglish = document.location.pathname.includes('/en')
  const testovani = localStorage.getItem('new-online-form-version')
  const form_name = isEnglish ? 'kalkulacka_en' : 'kalkulacka'
  const language = isEnglish ? 'en' : 'cs'

  return await api.post('/application', {
    branchId: GATSBY_BRANCH_ID,
    language,
    form_name:data.form_name ? data.form_name : form_name,
    region: 'CZ',
    name: data.name,
    phone: formatPhoneNumber(data.phone),
    checkTerms: data.terms1,
    checkTerms2: data.terms2,
    price: data.price,
    promo: data.promo,

    plan: getPlan(data),
    program: getProgram(data.program),
    menu: getMenu(data),
    osob: getOsob(data),

    cjevent: data.affiliateId,
    utm_source: UTMS.UTM_SOURCE,
    utm_medium: UTMS.UTM_MEDIUM,
    utm_campaign: UTMS.UTM_CAMPAIGN,
    utm_term: UTMS.UTM_TERM,
    utm_content: UTMS.UTM_CONTENT,
    referrer,
    testovani:data.testovani ? data.testovani : testovani ,
    roistat: getCookie('roistat_visit'),
    ga: getCookie('_ga'),
  })
}

const getPlan = data => {
  if (isOrderFoodProgramWeightLoss(data)) {
    return 'Zhubnout'
  }
  if (isOrderFoodProgramWeightGain(data)) {
    return 'Nabírat'
  }
  if (isOrderFoodProgramMaintenance(data)) {
    return 'Udržovat'
  }
  if (isOrderFoodProgramLunchAndDinner(data)) {
    return 'Oběd a večeře'
  }
}

const getDiet = data => {
  if (isOrderFoodProgramWeightLoss(data)) {
    return 'loose'
  }
  if (isOrderFoodProgramWeightGain(data)) {
    return 'gain'
  }
  if (isOrderFoodProgramMaintenance(data)) {
    return 'keep'
  }
  if (isOrderFoodProgramLunchAndDinner(data)) {
    return 'keep'
  }
}

const getProgram = data => {
  if (isOrderProgramDuration2Weeks(data)) {
    return '2 týdny'
  }
  if (isOrderProgramDuration1Month(data)) {
    return 'Měsíc'
  }
  if (isOrderProgramDuration2Month(data)) {
    return 'Dva měsíce'
  }
  if (isOrderProgramDuration3Month(data)) {
    return 'Tři měsíce'
  }
}

const getMenu = data => {
  const numberOfMeals = data.numberOfMeals
  const mealsSize = numberOfMeals.length

  if (mealsSize === 2) {
    return '2chodové menu'
  }

  if (mealsSize === 3) {
    return '3chodové menu'
  }

  if (mealsSize === 4) {
    return '4chodové menu'
  }

  if (mealsSize === 5) {
    return '5chodové menu'
  }
}

const getOsob = data => {
  if (isOrderFoodNumberOfPeopleOne(data)) {
    return '1 osoba'
  }
  if (isOrderFoodNumberOfPeopleTwo(data)) {
    return '2 osoby'
  }
  if (isOrderFoodNumberOfPeopleThreeOrMore(data)) {
    return '3 osoby'
  }
  if (isOrderFoodNumberOfPeopleCompany(data)) {
    return '>5'
  }
}

const getSize = data => {
  if (data.isOrderFoodWeekMeal5DaysLength) {
    return 'short'
  }
  if (data.isOrderFoodWeekMeal6DaysLength) {
    return 'long'
  }
  return null
}

const getDeliveryTime = data => {
  const time = data?.deliveryTime || []
  const formattedTimes = time.map(x => {
    const date = new Date(x);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  });
  return formattedTimes.join(' - ');
};

export const clientApi = {
  checkPromoCode,
  checkDeliveryAddress,
  application,
  order,
}
