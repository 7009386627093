// Place any global data in this file.
// You can import this data from anywhere in your site by using the `import` keyword.

// system
const NODE_ENV = process.env.NODE_ENV
const development = NODE_ENV === "development"
const production = NODE_ENV === "production"

// Server
const SERVER_PORT = Number.parseInt(process.env.SERVER_PORT || "3000", 10)

// Api
const ENDPOINT_API_NAME_CLIENT = process.env.GATSBY_APP_API_URL // process.env.ENDPOINT_API_NAME_CLIENT
const ENDPOINT_API_NAME_SERVER = process.env.ENDPOINT_API_NAME_SERVER
const ENDPOINT_API_NAME_MOCK_SERVER = process.env.ENDPOINT_API_NAME_MOCK_SERVER

const GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_API_KEY

const GATSBY_BRANCH_ID = process.env.GATSBY_BRANCH_ID

module.exports = {
  NODE_ENV,
  development,
  production,
  SERVER_PORT,
  ENDPOINT_API_NAME_CLIENT,
  ENDPOINT_API_NAME_SERVER,
  ENDPOINT_API_NAME_MOCK_SERVER,
  GOOGLE_API_KEY,
  GATSBY_BRANCH_ID,
}
