import React from 'react'
import cx from 'classnames'
import styles from './select-button.module.css'
import {
  CalendarIcon,
  CalendarSuccessIcon,
  TimeIcon,
  TimeSuccessIcon,
} from '../icons'

export const SelectButton = ({ children, value, ...props }) => {
  return (
    <button
      {...props}
      type="button"
      className={cx(styles.button, { [styles.active]: !!value })}
    >
      {children}
    </button>
  )
}

export const SelectCalendarButton = ({ children, value, ...props }) => {
  return (
    <div className={cx(styles.buttonBlock)}>
      <SelectButton {...props} value={value}>
        <span className={styles.icon}>
          {!!value ? <CalendarSuccessIcon /> : <CalendarIcon />}
        </span>
        <div>{value || children}</div>
      </SelectButton>
    </div>
  )
}

export const SelectTimeButton = ({ children, value, ...props }) => {
  return (
    <div className={cx(styles.buttonBlock)}>
      <SelectButton {...props} value={value}>
        <span className={styles.icon}>
          {!!value ? <TimeSuccessIcon /> : <TimeIcon />}
        </span>
        <div>{value || children}</div>
      </SelectButton>
    </div>
  )
}
