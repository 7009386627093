const fetch = require('cross-fetch')
const { omit } = require('lodash')

const CONTENT_TYPE = 'Content-Type'

const createApiRequest = (serverUri, defaultOptions = { headers: {} }) => {
  /**
   * @param {string} url
   * @param {Object} requestOptions
   * @returns {Promise<AxiosResponse<any>>}
   */
  const request = (url, requestOptions = {}) => {
    const requestUrl = createFetchPath(serverUri, url)

    return fetch(requestUrl, requestOptions).then((response) => {
      return response.json()
    })
  }

  /**
   * @param {string} url
   * @param {Object} requestOptions
   * @returns {Promise<any>}
   */
  const get = (url, requestOptions) => {
    const method = 'GET'
    const data = init(method, defaultOptions, requestOptions)
    return request(url, omit(data, ['body']))
  }

  /**
   * @param {string} url
   * @param {any} body
   * @param {Object} requestOptions
   * @returns {Promise<any>}
   */
  const post = (url, body, requestOptions) => {
    const method = 'POST'
    const data = init(method, defaultOptions, requestOptions, body)
    return request(url, data)
  }

  /**
   * @param {string} url
   * @param {any} body
   * @param {Object} requestOptions
   * @returns {Promise<any>}
   */
  const put = (url, body, requestOptions) => {
    const method = 'PUT'
    const data = init(method, defaultOptions, requestOptions, body)
    return request(url, data)
  }

  /**
   * @param {string} url
   * @param {any} body
   * @param {Object} requestOptions
   * @returns {Promise<any>}
   */
  const patch = (url, body, requestOptions) => {
    const method = 'PATCH'
    const data = init(method, defaultOptions, requestOptions, body)
    return request(url, data)
  }

  /**
   * @param {string} url
   * @returns {Promise<any>}
   */
  const deleteRecord = (url) => {
    const method = 'DELETE'
    const data = init(method, defaultOptions)
    return request(url, data)
  }

  return {
    get,
    post,
    put,
    patch,
    delete: deleteRecord,
  }
}

const init = (method, defaultOptions, requestOptions, body) => {
  const defaultHeaders = (defaultOptions && defaultOptions.headers) || {}
  const requestHeaders =
    requestOptions && requestOptions.headers ? requestOptions.headers : {}

  const contentType =
    requestOptions &&
    requestOptions.headers &&
    requestOptions.headers[CONTENT_TYPE]
      ? requestOptions.headers[CONTENT_TYPE]
      : 'application/json;charset=UTF-8'

  const headers = {
    ...defaultHeaders,
  }

  const result = {
    method,
    withCredentials: true,
    headers: {
      ...headers,
      [CONTENT_TYPE]: contentType,
    },
    body: JSON.stringify(body),
  }

  return result
}

const createFetchPath = (endpoint, pathname) => {
  const p = pathname
    .replace(endpoint, '')
    .replace('/api/api/', '/api/')
    .replace('/api/', '/')
    .replace('//', '/')
    .replace('//', '/')
    .replace('//', '/')
    .replace(endpoint, '')

  return `${endpoint}${p}`.replace('/api/api/', '/api/')
}

module.exports = { createApiRequest }
