const { createApiRequest } = require('../lib/request')
const {
  ENDPOINT_API_NAME_CLIENT,
  ENDPOINT_API_NAME_SERVER,
} = require('../lib/config')

const api = createApiRequest(ENDPOINT_API_NAME_CLIENT)
const apiServer = createApiRequest(ENDPOINT_API_NAME_SERVER)

module.exports = {
  api,
  apiServer,
}
