import React from 'react'
import styles from './label.module.css'

export const Label = ({ required, children }) => {
  return (
    <label className={styles.label}>
      <span>{children}</span>
      {required && <span className={styles.required}>{' *'}</span>}
    </label>
  )
}
