import React, { useRef } from 'react'
import cx from 'classnames'
import { MaskedPhoneInput } from '../../../../../components/forms/phone'
import styles from './phone-input.module.css'

export const PhoneInput = (props) => {
  const { placeholder, value, error, onChange } = props

  const phoneRef = useRef()

  return (
    <div className={styles.block}>
      <MaskedPhoneInput
        name="phone"
        placeholder={placeholder}
        value={value}
        error={error}
        isWhite
        innerRef={phoneRef}
        onChange={onChange}
        className={cx(styles.input, {
          [styles.error]: !!error,
        })}
      />
      {error && <ErrorText error={error} />}
    </div>
  )
}

const ErrorText = (props) => {
  const { error } = props
  return <span className={styles.errorMessage}>{error}</span>
}
