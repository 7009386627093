import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { clientApi } from '../../../shared/api'
import {
  PromoCodeInput as PromoCodeInputView,
  PromoCodeStatus,
} from '../../pages/order-food-online/components/form'

export const PromoCodeInput = props => {
  const { placeholder, totalPrice, onChange, isPromoApplyed } = props
  const { t } = useTranslation()

  const [promoCodeInoutValue, setPromoCodeInputValue] = useState('')
  const [promoCodeStatus, setPromoCodeStatus] = useState(
    PromoCodeStatus.DEFAULT
  )
  const [isApplyed, setIsApplyed] = useState(false) // isPromoApplyed || false

  // useEffect(() => {
  //   setIsApplyed(isPromoApplyed)
  // }, [isPromoApplyed]) // TODO: not sure if it's necessary - override from outside this state

  useEffect(() => {
    setPromoCodeStatus(PromoCodeStatus.DEFAULT)
  }, [promoCodeInoutValue])

  const handlePromoChange = promoCode => {
    setPromoCodeInputValue(promoCode)
  }

  const handleClearValue = () => {
    setPromoCodeInputValue('')
  }

  const handlePromoClick = async promoCode => {
    if (promoCode?.length >= 3) {
      const result = await clientApi.checkPromoCode({
        promoCode: promoCodeInoutValue,
        price: totalPrice,
      })

      setPromoCodeInputValue(promoCode)

      if (result?.discount >= 1) {
        setPromoCodeStatus(PromoCodeStatus.SUCCESS)
        onChange({ ...result, value: promoCodeInoutValue })
        setIsApplyed(true)
      }
      if (result?.discount <= 0) {
        setPromoCodeStatus(PromoCodeStatus.FAIL)
        onChange({ ...result, value: promoCodeInoutValue })
        setIsApplyed(false)
      }
      if (result?.error) {
        setPromoCodeStatus(PromoCodeStatus.FAIL)
        setIsApplyed(false)
      }
    } else {
      setPromoCodeStatus(PromoCodeStatus.FAIL)
      setIsApplyed(false)
    }
  }

  return (
    <PromoCodeInputView
      placeholder={placeholder}
      value={promoCodeInoutValue}
      status={promoCodeStatus}
      error={t('forms.onlineOrderFormPromoErrorMsg')}
      onClick={handlePromoClick}
      onChange={handlePromoChange}
      onClear={handleClearValue}
      disabled={isApplyed}
    >
      {t('forms.onlineOrderFormPromoCodeBtn')}
    </PromoCodeInputView>
  )
}
