import React from 'react'
import styles from './order-food-online-sidebar.module.css'
import cx from 'classnames';

export const OrderFoodOnlineSidebarCalculationBlock = ({ children }) => {
  return (
    <div className={styles.resultBlock}>
      <div className={styles.calculationBlock}>{children}</div>
    </div>
  )
}
